import React from 'react';
import { connect } from 'react-redux';
import loadable from '@loadable/component';
import { withTheme } from 'styled-components';
import { withRouter } from 'react-router-dom';
import _ from 'lodash';

import { getActiveRouteSelector } from 'common/selectors';
import { getPageDataSelector } from 'common/selectors/page';
import { getGoogleApiKeySelector } from 'common/selectors/index';
import { reactiveTemplateConfigSelector } from 'common/selectors/theme';

import { fetchOperationUnitsData } from 'common/actions/PageActions';

import { setToObject } from 'common/utils';

const StoresVariant = loadable(props => import(`./${props.blockVariant}`));

const Stores = ({
  blockSettings = 'StoresVariant1',
  googleApiKey,
  operationUnits,
  operationUnitsFiltered,
  filterStores,
  resetFilters,
  ...rest
}) => {
  return (
    <StoresVariant
      blockVariant={_.get(blockSettings, 'blockVariant')}
      googleApiKey={googleApiKey}
      operationUnits={operationUnits}
      operationUnitsFiltered={operationUnitsFiltered}
      filterStores={filterStores}
      resetFilters={resetFilters}
      {...rest}
    />
  );
};

const blockSettings = {};
const makeMapStateToProps = () => {
  const getPageData = getPageDataSelector();
  const getGoogelApiKey = getGoogleApiKeySelector();
  const  getActiveRoute = getActiveRouteSelector();
  const reactiveTemplateConfig = reactiveTemplateConfigSelector(
    'StoresVariant'
  );
  return (state, props) => {
    const pageData = getPageData(state, props);
    return {
      activeRoute: getActiveRoute(state, props),
      operationUnits: _.get(pageData, 'operationUnits', null),
      operationUnitsFiltered: _.get(pageData, 'operationUnitsFiltered', null),
      googleApiKey: getGoogelApiKey(state, props),
      blockSettings: setToObject(blockSettings, {
        blockVariant: reactiveTemplateConfig(state, props)
      })
    };
  };
};

const mapDispatchToProps = (dispatch, props) => {
  return {
    filterStores: values => {
      const { history } = props;
      dispatch(fetchOperationUnitsData(values, history));
    },
    resetFilters: () => {
      const { history } = props;
      dispatch(fetchOperationUnitsData({}, history));
    }
  };
};

const StoresConnected = connect(
  makeMapStateToProps,
  mapDispatchToProps
)(Stores);

export default withTheme(withRouter(StoresConnected));
