import React from 'react';
import { connect } from 'react-redux';
import _ from 'lodash';
import loadable from '@loadable/component';

const TopBarVariant = loadable((props) =>
  import(`common/blocks/topBar/${props.blockVariant}`)
);

import {
  isLayoutTopBarEnabled,
  getLayoutTopbarSettings,
  reactiveTemplateConfigSelector,
} from 'common/selectors/theme';

import { getTopBarMenuSelector } from 'common/selectors/page';
import {
  getWebInstanceEmailsSelector,
  getWebInstanceSettingsSelector,
} from 'common/selectors/index';

import { setToObject } from 'common/utils';

const TopBar = ({
  webInstanceEmails = {},
  settings = {},
  topbarSettings,
  topbarMenu,
  blockSettings,
}) => {
  if (!_.get(blockSettings, 'isTopbarEnabled')) {
    return null;
  }

  const topBarData = {
    data: {
      phone: _.get(settings, 'phone', false),
      email: _.get(webInstanceEmails, 'adminEmail', false),
      facebook: {
        active:
          _.get(topbarSettings, 'isFacebookEnabled', true) &&
          _.get(settings, 'facebook_link', false)
            ? true
            : false,
        link: _.get(settings, 'facebook_link', false),
        name: _.get(settings, 'facebook_name', false)
          ? settings.facebook_name
          : 'Facebook',
      },
      instagram: {
        active: _.get(settings, 'instagram_link', false) ? true : false,
        link: _.get(settings, 'instagram_link', false),
        name: _.get(settings, 'instagram_name', false)
          ? settings.instagram_name
          : 'Instagram',
      },
      google: {
        active: _.get(settings, 'google_link', false) ? true : false,
        link: _.get(settings, 'google_link', false),
        name: _.get(settings, 'google_name', false)
          ? settings.google_name
          : 'Google',
      },
      youtube: {
        active: _.get(settings, 'youtube_link', false) ? true : false,
        link: _.get(settings, 'youtube_link', false),
        name: _.get(settings, 'youtube_name', false)
          ? settings.youtube_name
          : 'YouTube',
      },
    },
    topbarSettings: topbarSettings,
    topbarMenu: topbarMenu,
  };

  return (
    <TopBarVariant
      blockVariant={_.get(blockSettings, 'blockVariant')}
      {...topBarData}
    />
  );
};

let blockSettings = {};
const makeMapStateToProps = () => {
  const getTopBarMenu = getTopBarMenuSelector();
  const getWebInstanceEmails = getWebInstanceEmailsSelector();
  const isTopbarEnabled = isLayoutTopBarEnabled();
  const getWebInstanceSettings = getWebInstanceSettingsSelector();
  const topbarSettings = getLayoutTopbarSettings();
  const reactiveTemplateConfig = reactiveTemplateConfigSelector(
    'TopBarVariant'
  );
  return (state, props) => {
    return {
      webInstanceEmails: getWebInstanceEmails(state, props),
      settings: getWebInstanceSettings(state, props),
      topbarSettings: topbarSettings(state, props),
      topbarMenu: getTopBarMenu(state, props),
      blockSettings: setToObject(blockSettings, {
        isTopbarEnabled: isTopbarEnabled(state, props),
        blockVariant: reactiveTemplateConfig(state, props),
      }),
    };
  };
};

export default connect(makeMapStateToProps)(TopBar);
