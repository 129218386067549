import React from 'react';

import StoresVariant from 'common/blocks/stores/';

const Stores = () => {
  return (
    <StoresVariant />
  )
}

export default Stores;
